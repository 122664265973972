//import './vendor/fontawesome.js';
import axios from 'axios';

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// mobile explain text toggle
const open_class = "open";
const $exp_hs = document.querySelectorAll('.explanations h1');
const [...$exp_ps] = document.querySelectorAll('.explanations p');
$exp_hs.forEach(($exp_h, idx) => {
    $exp_h.addEventListener('click', e => {
        e.preventDefault();
        const $exp_p = $exp_ps[idx];
        $exp_p.classList.toggle(open_class);
    });
});

const select_class = "selected";
const $subs = document.querySelectorAll('.subscription-column');
const $mobile_subs = document.querySelectorAll('.mobile-features');
const $payment_share_input = document.querySelectorAll('.payment-share .switch-toggle input');
const $low_share = document.querySelector('.payment-share .switch-toggle label[for=low-share]');
const $mid_share = document.querySelector('.payment-share .switch-toggle label[for=mid-share]');
const $high_share = document.querySelector('.payment-share .switch-toggle label[for=high-share]');
const $low_duration = document.querySelector('.contract-runtime .switch-toggle label[for=low-duration]');
const $mid_duration = document.querySelector('.contract-runtime .switch-toggle label[for=mid-duration]');
const $high_duration = document.querySelector('.contract-runtime .switch-toggle label[for=high-duration]');
const $runtime_input = document.querySelectorAll('.contract-runtime .switch-toggle input');
//const $price_display = document.querySelector('.calculated-price .price-value');

$subs.forEach(($sub, idx) => {
    $sub.addEventListener('click', e => {
        e.preventDefault();
        $subs.forEach($sub => {
            $sub.classList.remove(select_class);
        });
        $mobile_subs.forEach($sub => {
            $sub.classList.remove(select_class);
        });
        $sub.classList.add(select_class);
        $mobile_subs[idx].classList.add(select_class);
        const shares = price_tree.payment_share_map['CHF'][$sub.dataset.key];
        $low_share.textContent = "CHF " + shares['low-share'];
        $mid_share.textContent = "CHF " + shares['mid-share'];
        $high_share.textContent = "CHF " + shares['high-share'];
    });
});

$low_duration.textContent = price_tree.contract_runtimes[0] + " months";
$mid_duration.textContent = price_tree.contract_runtimes[1] + " months";
$high_duration.textContent = price_tree.contract_runtimes[2] + " months";

const calculate_price = (subscription, payment_share, contract_runtime) => {
    return price_tree.calculations['CHF'][subscription][payment_share][contract_runtime];
};

const $digits = document.querySelectorAll('.digits');
const animate_price_change = ($digits, new_num) => {
    let new_digits = new_num.toString().split('');
    for (let i = new_digits.length - 1; i < $digits.length; i++) {
        new_digits.push(' ');
    }
    $digits.forEach(($digit_wrapper, key) => {
        const $digit = $digit_wrapper.querySelector('span');
        const is_same = $digit.textContent === new_digits[key];
        const $old_digit = $digit_wrapper.querySelector('.change-old');
        if ($old_digit !== null) {
            $digit_wrapper.removeChild($old_digit);
        }
        $digit.classList.remove('change-new');
        if (is_same) {
            return;
        }
        $digit.classList.add('change-old');
        let $new_digit = document.createElement("span");
        $new_digit.classList.add('change-new');
        let new_digit = document.createTextNode(new_digits[key]);
        $new_digit.appendChild(new_digit);
        $digit_wrapper.appendChild($new_digit);
        setTimeout(() => {
            $new_digit.classList.remove('change-new');
        }, 10);
        setTimeout(() => {
            try {
                $digit_wrapper.removeChild($digit);   
            } catch (error) {
                //console.log(error);
            }
        }, 200);
    });
};

const handle_price_change = () => {
    const subscription = document
        .querySelector('.subscription-column' + '.' + select_class)
        .dataset.key;
    const payment_share = document
        .querySelector('.payment-share .switch-toggle input[type=radio]:checked')
        .value;
    const contract_runtime = document
        .querySelector('.contract-runtime .switch-toggle input[type=radio]:checked')
        .value;
    const price = calculate_price(subscription, payment_share, contract_runtime);
    //$price_display.textContent = price;
    animate_price_change($digits, price);
};

$payment_share_input.forEach($el => {
    $el.addEventListener('change', e => {
        e.preventDefault();
        handle_price_change();
    });
});
$runtime_input.forEach($el => {
    $el.addEventListener('change', e => {
        e.preventDefault();
        handle_price_change();
    });
});
$subs.forEach($sub => {
    $sub.addEventListener('click', e => {
        e.preventDefault();
        handle_price_change();
    });
});

handle_price_change();

const register_info_modal = ($info_modal, $info_close, $info_button, modal_open_class = "open") => {
    $info_button.addEventListener('click', e => {
        e.preventDefault();
        $info_modal.classList.add(modal_open_class);
        document.querySelector('body').classList.add('no-scroll');
        document.querySelector('html').classList.add('no-scroll');
    });
    
    $info_close.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        $info_modal.classList.remove(modal_open_class);
        document.querySelector('body').classList.remove('no-scroll');
        document.querySelector('html').classList.remove('no-scroll');
    });
};

const $info_modal_subs = document.querySelector('.info-modal-subs');
const $info_button_subs = document.querySelector('.subscription-info');
const $info_close_subs = $info_modal_subs.querySelector('.close-icon');
register_info_modal($info_modal_subs, $info_close_subs, $info_button_subs);


const register_message_modal = ($modal, messages, modal_open_class = "open") => {
    $modal.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();
        $modal.classList.remove(modal_open_class);
        document.querySelector('body').classList.remove('no-scroll');
        document.querySelector('html').classList.remove('no-scroll');
    });

    return (message) => {
        let text = messages.default;
        if (messages[message]) {
            text = messages[message];
        }
        $modal.querySelector('.modal-message').textContent = text;
        $modal.classList.add(modal_open_class);
        document.querySelector('body').classList.add('no-scroll');
        document.querySelector('html').classList.add('no-scroll');
    };
};

const $message_modal_success = document.querySelector('.success-modal');
const success_messages = {
    'default': 'We’ve sent you the proposal and we’ll contact you in the next 24 hours.'
};
const modal_success_handler = register_message_modal($message_modal_success, success_messages);

const $message_modal_failure = document.querySelector('.failure-modal');
const failure_messages = {
    'email': 'That’s not quiet an valid e-mail address. Try again!',
    'conflict': 'There was a conflict, please refresh the site and try again!',
    'default': 'Something happened, please refresh and try again later!'
};
const modal_failure_handler = register_message_modal($message_modal_failure, failure_messages);

// TODO
//const $info_modal_price = document.querySelector('.info-modal-price');
//const $info_button_price = document.querySelector('.price-info');
//register_info_modal($info_modal_price, $info_button_price);

const $quote_button = document.querySelector('.proposal-button');
const $email_field = document.querySelector('.email-input');
const $email_input = $email_field.querySelector('input');
const $email_send = $email_field.querySelector('a.button');
const hide_class = 'hide';
let proposal_state = false;

const handle_quote = () => {
    proposal_state = !proposal_state;
    if (proposal_state) {
        $quote_button.classList.add(hide_class);
        $email_field.classList.remove(hide_class);
    } else {
        $quote_button.classList.remove(hide_class);
        $email_field.classList.add(hide_class);
    }
};

const email_regex = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

const handle_input = () => {
    if ($email_input.validity.valid && email_regex.test($email_input.value)) {
        $email_send.disabled = false;
        $email_send.removeAttribute('disabled');
    } else {
        $email_send.disabled = true;
        $email_send.setAttribute('disabled', true);
    }
    //console.log($email_send);
};

$quote_button.addEventListener('click', e => {
    e.preventDefault();
    handle_quote();
    handle_input();
    $email_input.focus();
});

$email_input.addEventListener('input', handle_input);

const handle_send = e => {
    e.preventDefault();
    document.querySelector('.message-email').textContent = $email_input.value;
    axios({
        method: 'post',
        url: '/quote',
        data: JSON.stringify({
            version: price_tree.version,
            email: $email_input.value,
            stage: document.querySelector('.company-selection .dropdown button span').textContent,
            currency: 'CHF',
            subscription: document.querySelector('.subscription-column' + '.' + select_class).dataset.key,
            payment_share: document.querySelector('.payment-share .switch-toggle input[type=radio]:checked').value,
            contract_runtime: document.querySelector('.contract-runtime .switch-toggle input[type=radio]:checked').value
        }),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    }).then(res => {
        modal_success_handler('default');
        return;
    }).catch(err => {
        if (err.response) {
            if (err.response.status === 409) {
                modal_failure_handler('conflict');
                return;
            }
            if (err.response.status === 422) {
                modal_failure_handler('email');
                return;
            }
            // response made, but something is wrong
            modal_failure_handler('default');
            return;
        }
        if (err.request) {
            // request made but no response
            modal_failure_handler('default');
            return;
        }
        // request not made
        modal_failure_handler('default');
        return;
    });
};

$email_send.addEventListener('click', handle_send);

const $comp_dd = document.querySelector('.company-selection .dropdown');
const $comp_btn = $comp_dd.querySelector('button');
const $comp_active = $comp_btn.querySelector('span');
const $comp_opt = $comp_dd.querySelectorAll('a');
const active_class = 'is-active';

$comp_btn.addEventListener('click', e => {
    e.preventDefault();
    $comp_dd.classList.toggle(active_class);
});

$comp_opt.forEach($opt => {
    $opt.addEventListener('click', e => {
        e.preventDefault();
        $comp_dd.classList.remove(active_class);
        $comp_opt.forEach($other_opt => {
            $other_opt.classList.remove(active_class);
        });
        $opt.classList.add(active_class);
        $comp_active.textContent = $opt.textContent;
    })
});


const $fade_group = document.querySelectorAll('.fade-group');
const fade_ready_class = 'fade-ready';
setTimeout(() => {
    $fade_group.forEach($el => $el.classList.remove(fade_ready_class));
}, 500);
